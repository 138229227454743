import React from 'react';

import Seo from '../components/seo';
import { Header, HeaderLink } from '../components/org.header/header.component';
import CustomThemeProvider from '../components/atm.custom-theme-provider/custom-theme-provider.component';
import '@fontsource/montserrat';
import { Box, Typography } from '@material-ui/core';
import { CompanyCard } from '../components/mol.company-card/company-card.component';
import { Spacing } from '../components/obj.constants/constants';
import CotationForm from '../components/org.cotation-form/cotation-form.container';
import { Grid } from '@material-ui/core';

const IndexPage = () => (
  <>
    <Seo title="Cotação" />
    <Box paddingTop={Spacing.XLarge} paddingBottom={Spacing.XLarge}>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item xs={12} />
        <Grid item xs={10} md={6} lg={4}>
          <CotationForm />
        </Grid>
      </Grid>
    </Box>
  </>
);

const headerLinks: HeaderLink[] = [
  { text: 'Faça sua Cotação', href: '/cotacao' },
  { text: 'Nossos Produtos', href: '/produtos' },
  { text: 'Parceiros', href: '/parceiros' },
  { text: 'Quem somos', href: '/quem-somos' },
];
export default IndexPage;
